import {
  Button,
  Checkbox,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorRow } from '~/components/common/error-row';
import { useModalAction } from '~/hooks/modal/useModalAction';
import { usePdfLocations } from '~/hooks/usePdfLocations';
import { useTranslations } from '~/hooks/useTranslations';

const initialValues = {
  terms: true,
  marketing: true,
};

export function TermsModal({
  acceptTerms,
  needsTermsAcceptance,
  applyMarketingConsent,
  marketingPrompt,
}) {
  const { genericTranslation } = useTranslations();
  const { closeModal } = useModalAction();

  const pdfLocations = usePdfLocations();

  const validationSchema = Yup.object().shape({
    terms: Yup.boolean().oneOf([true], genericTranslation.validation.termsRequired),
    marketing: Yup.boolean(),
  });

  const onSubmit = useCallback(
    ({ marketing }) => {
      if (marketingPrompt) {
        applyMarketingConsent?.(marketing);
      }
      if (needsTermsAcceptance) {
        acceptTerms();
      }
      closeModal();
    },
    [acceptTerms, applyMarketingConsent, marketingPrompt, needsTermsAcceptance, closeModal]
  );

  const { handleSubmit, values, handleChange, errors } = useFormik({
    isInitialValid: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const disabledSubmit = Object.keys(errors).length;

  return (
    <Modal isCentered isOpen size='xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent maxWidth={600}>
        <ModalHeader textAlign='center' mb={0}>
          Review and Accept
        </ModalHeader>
        <Divider />
        <ModalBody py={6}>
          <form onSubmit={handleSubmit}>
            <VStack>
              {needsTermsAcceptance && (
                <VStack alignItems='flex-start'>
                  <HStack>
                    <Checkbox
                      id='terms'
                      name='terms'
                      type='checkbox'
                      spacing={4}
                      isChecked={values.terms}
                      onChange={handleChange}
                    >
                      <Text as='span'>
                        {`By checking this box, I acknowledge and agree to the `}
                        <Link to={pdfLocations.TERMS_AND_CONDITIONS} target='_blank'>
                          <Text as='span' display='inline' color='secondary_color' m={0} p={0}>
                            Terms & Conditions
                          </Text>
                        </Link>
                        .
                      </Text>
                    </Checkbox>
                  </HStack>
                  <ErrorRow isPresent={Boolean(errors['terms'])} ml={8}>
                    {errors['terms']}
                  </ErrorRow>
                </VStack>
              )}
              {marketingPrompt && (
                <VStack alignItems='flex-start'>
                  <HStack>
                    <Checkbox
                      id='marketing'
                      name='marketing'
                      type='checkbox'
                      spacing={4}
                      isChecked={values.marketing}
                      onChange={handleChange}
                    >
                      <Text as='span'>{marketingPrompt.text}</Text>
                    </Checkbox>
                  </HStack>
                  <ErrorRow isPresent={Boolean(errors['marketing'])} ml={8}>
                    {errors['marketing']}
                  </ErrorRow>
                </VStack>
              )}
              <Button type='submit' mt={4} onClick={handleSubmit} isDisabled={disabledSubmit}>
                Continue
              </Button>
              <Link to={pdfLocations.PRIVACY_POLICY} target='_blank'>
                <Text as='span' color='secondary_color'>
                  Privacy Policy
                </Text>
              </Link>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

TermsModal.propTypes = {
  needsTermsAcceptance: PropTypes.bool,
  acceptTerms: PropTypes.func.isRequired,
  applyMarketingConsent: PropTypes.func,
  marketingPrompt: PropTypes.shape({
    marketing_revision_id: PropTypes.number,
    text: PropTypes.string,
  }),
};
