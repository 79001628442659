import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useCategories } from '~/hooks/useCategories';
import usePublisherName from '~/hooks/usePublisherName';
import { RESTAURANTS } from '~/lib/constants';
import { Restaurants } from './restaurants';

export function RestaurantsPage() {
  const [isReady, setIsReady] = useState(false);
  const { publisherName } = usePublisherName();
  const { userZipCode } = useAccountState();
  const { categories } = useCategories();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const restaurantsCategory = categories.find(
      (category) => category.category.toLowerCase() === RESTAURANTS.toLowerCase()
    );

    const categoryId = restaurantsCategory?.category_id?.toString();
    const currentSearchLocation = params.get('search_location');
    const currentCategoryId = params.get('category_id');

    if (!currentCategoryId) {
      setIsReady(false);
    }

    if (userZipCode && currentSearchLocation === null) {
      params.set('search_location', userZipCode);
    }

    if (categoryId && (!currentCategoryId || currentCategoryId !== categoryId)) {
      params.set('category_id', categoryId);
    }

    const newUrl = `${location.pathname}?${params.toString()}`;
    if (newUrl !== location.search) {
      navigate(newUrl, { replace: true });
    }
  }, [categories, location.pathname, location.search, navigate, userZipCode]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const restaurantsCategory = categories.find(
      (category) => category.category.toLowerCase() === RESTAURANTS.toLowerCase()
    );

    const categoryId = restaurantsCategory?.category_id?.toString();

    if (categoryId && params.get('category_id') === categoryId && !isReady) {
      setIsReady(true);
    }
  }, [categories, location.search, isReady]);

  return (
    <Fragment>
      <Helmet>
        <title>Restaurants | {publisherName}</title>
      </Helmet>
      {isReady ? <Restaurants /> : null}
    </Fragment>
  );
}
