import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import usePublisherName from '~/hooks/usePublisherName';
import { SearchList } from '~/pages/search/search-list';
import { RestaurantsPage } from './restaurants-page';

export function Category() {
  const { categoryName } = useParams();
  const { publisherName } = usePublisherName();

  if (categoryName === 'restaurants') {
    return <RestaurantsPage />;
  }
  return (
    <Fragment>
      <Helmet>
        <title>Search | {publisherName}</title>
      </Helmet>
      <SearchList />
    </Fragment>
  );
}
