import { useCallback } from 'react';
import { BadStatusError } from '/src/lib/errors/index.js';
import { responseStatusIsGood } from '/src/lib/helpers.js';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { RESEND_VERIFICATION_URL, SIGN_UP_URL } from '~/lib/constants';

export const useAccountApi = () => {
  const fetchUrl = useFetchUrl();

  const signUp = useCallback(async (email, password, zipCode) => {
    if (!email || !password) {
      throw new Error('Email and password are required');
    }

    if (zipCode && !/^\d{5}$/.test(zipCode)) {
      throw new Error('Zip code must be 5 digits.');
    }

    const awsWafApi = window?.AwsWafIntegration?.fetch || fetch;

    try {
      const response = await awsWafApi(SIGN_UP_URL, {
        body: JSON.stringify({ email, password, primary_zip_code: zipCode || undefined }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      if (!responseStatusIsGood(response)) {
        throw new BadStatusError(response);
      }

      return await response.json();
    } catch (error) {
      console.error('Sign up error', error);
      throw error;
    }
  }, []);

  const resendEmailVerification = useCallback(
    async (email) => {
      try {
        await fetchUrl(
          `${RESEND_VERIFICATION_URL}?email=${encodeURIComponent(email)}`,
          { method: 'GET' },
          { omitAccessToken: true }
        );

        return;
      } catch (error) {
        console.log('Resending email verification error: ', error);
        throw error;
      }
    },
    [fetchUrl]
  );

  return { resendEmailVerification, signUp };
};
