import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const ChevronDownIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 16 16' fill='none' color={color}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
      >
        <path
          d='M6.50635 4.15234C6.76416 3.87305 7.21533 3.87305 7.47314 4.15234L11.5981 8.27734C11.8774 8.53516 11.8774 8.98633 11.5981 9.24414C11.3403 9.52344 10.8892 9.52344 10.6313 9.24414L7.00049 5.61328L3.34814 9.24414C3.09033 9.52344 2.63916 9.52344 2.38135 9.24414C2.10205 8.98633 2.10205 8.53516 2.38135 8.27734L6.50635 4.15234Z'
          fill={color}
        />
      </svg>
    </Icon>
  );
};

ChevronDownIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default ChevronDownIcon;
