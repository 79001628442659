import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const ChevronDownIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 16 16' fill='none' color={color}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M7.43562 12.1367L2.72133 7.42243C2.40213 7.12779 2.40213 6.61217 2.72133 6.31752C3.01597 5.99833 3.5316 5.99833 3.82624 6.31752L8.00035 10.4671L12.1499 6.31752C12.4445 5.99833 12.9602 5.99833 13.2548 6.31752C13.574 6.61217 13.574 7.12779 13.2548 7.42243L8.54053 12.1367C8.24588 12.4559 7.73026 12.4559 7.43562 12.1367Z'
          fill={color}
        />
      </svg>
    </Icon>
  );
};

ChevronDownIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default ChevronDownIcon;
