import { Box, Button, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Carousel from '~/components/carousel/carousel';
import { DiningUtensilsIcon, NextArrowIcon, PrevArrowIcon } from '~/components/icons';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useModalAction } from '~/hooks/modal/useModalAction';
import { useCategories } from '~/hooks/useCategories';
import { useConfig } from '~/hooks/useConfig';
import { BRANDS_SEARCH_ROUTE, CAROUSEL, MODALS, QUERY_PARAMS, RESTAURANTS } from '~/lib/constants';
import imgUrl, { getCategoryIdFromCategories } from '~/lib/helpers';

const settings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 2000,
  autoplaySpeed: 4000,
};

export default function CategorySlider({ carouselCategories }) {
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const { isLoggedIn } = useAccountState();

  const { categories } = useCategories();

  const { openModal } = useModalAction();

  const { feature_flag } = useConfig();

  const responsive = useBreakpointValue(
    {
      base: 'none',
      md: 'block',
    },
    { fallback: 'md', ssr: false }
  );

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleClick = (carouselCategory) => {
    if (carouselCategory.clickType === CAROUSEL.CLICK_TYPES.SEARCH_PAGE) {
      const categoryId = getCategoryIdFromCategories(categories, carouselCategory.category_name);
      if (categoryId) {
        goToCategory(categoryId);
      }
    } else if (carouselCategory.clickType === CAROUSEL.CLICK_TYPES.SIGN_IN_MODAL) {
      openModal(MODALS.SIGN_IN);
    } else if (carouselCategory.clickType === CAROUSEL.CLICK_TYPES.CUSTOM_CATEGORY_PAGE) {
      navigate(carouselCategory.link);
    }
  };

  const goToCategory = (categoryId) => {
    const search = new URLSearchParams();
    search.set(QUERY_PARAMS.CATEGORY_ID, categoryId);
    navigate(`${BRANDS_SEARCH_ROUTE}?${search.toString()}`);
  };

  const loggedInStatus = isLoggedIn
    ? CAROUSEL.CONDITIONALS.USER_LOGGED_IN
    : CAROUSEL.CONDITIONALS.USER_NOT_LOGGED_IN;

  let filteredCategories = carouselCategories.map((category) => {
    if (!category.conditionals) {
      return category.default;
    }
    const conditionalCategory = category.conditionals.find(
      (conditionalCategory) => conditionalCategory.conditionCode === loggedInStatus
    );

    return conditionalCategory || category.default;
  });

  filteredCategories = filteredCategories.filter((category) => {
    if (category.key === RESTAURANTS && !feature_flag.show_start_dining_carousel) {
      return false;
    }
    return true;
  });

  const categoryGrid = filteredCategories.map((currentCategory) => (
    <Box data-role='carousel' key={currentCategory.key} layerStyle='carousel'>
      <Box
        data-role='carousel-background'
        layerStyle='carousel-background'
        backgroundImage={imgUrl(currentCategory?.image_path)}
        mx={2}
        width='calc(100% - 8px)'
      >
        <Box
          position='absolute'
          borderRadius='24px'
          top='0'
          left='0'
          width='100%'
          height='100%'
          bg='rgba(0, 0, 0, 0.4)'
        />
        <Box data-role='carousel-details' layerStyle='category-details' position='relative'>
          {currentCategory?.pill ? (
            <Stack
              alignItems='center'
              bgColor='orange.600'
              borderRadius='100px'
              color='white'
              flexDirection='row'
              mb='3'
              px='4'
              py='1'
              gap='1'
              width='fit-content'
            >
              <DiningUtensilsIcon color='white' size='14px' />
              <Text fontSize='sm' fontWeight='500'>
                {currentCategory.pill.text}
              </Text>
            </Stack>
          ) : null}
          <Box maxWidth={['100%', '100%', '40%', '40%']}>
            <Text variant='carousel-head' textStyle='carousel-title' fontWeight='600'>
              {currentCategory?.title}
            </Text>
          </Box>
          <Text variant='two-lines' textStyle='carousel-desc'>
            {currentCategory?.description}
          </Text>

          <Button
            variant='baseStyle'
            onClick={() => handleClick(currentCategory)}
            layerStyle='btn-carousel'
          >
            {currentCategory?.cta}
          </Button>
        </Box>
        {responsive === 'block' && (
          <>
            <Box cursor='pointer' onClick={previous} layerStyle='carousel-prev-arrow'>
              <PrevArrowIcon />
            </Box>
            <Box cursor='pointer' onClick={next} layerStyle='carousel-next-arrow'>
              <NextArrowIcon />
            </Box>
          </>
        )}
      </Box>
    </Box>
  ));

  return (
    <Carousel sliderRef={sliderRef} displayArrows {...settings}>
      {categoryGrid}
    </Carousel>
  );
}

CategorySlider.propTypes = {
  carouselCategories: PropTypes.array,
};
