import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { useAccountApi } from '~/api/useAccountApi';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useTranslations } from '~/hooks/useTranslations';
import { handleError } from '~/lib/errors';
import { Countdown } from '../countdown/countdown';

export function EmailVerificationModal({ onClose }) {
  const toast = useToast();
  const { resendEmailVerification } = useAccountApi();
  const { genericTranslation } = useTranslations();
  const { emailVerificationEmail } = useAccountState();
  const [isCountdownActive, setIsCountdownActive] = useState(false);

  const handleCountdownComplete = () => {
    setIsCountdownActive(false);
  };

  const handleClick = async () => {
    try {
      setIsCountdownActive(true);

      await resendEmailVerification(emailVerificationEmail);
    } catch (error) {
      handleError(toast, error, genericTranslation.emailVerification.failed);
      handleCountdownComplete();
    }
  };

  return (
    <Modal isOpen onClose={onClose} isCentered blockScrollOnMount>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader mb={0} pb={2} layerStyle='modal-sm-header'>
          <VStack>
            <Image alt='icon' src='/icons/danger-icon.svg' />
            <Text>{genericTranslation.emailVerification.title}</Text>
          </VStack>
        </ModalHeader>
        <ModalBody pb={4}>
          {genericTranslation.emailVerification.instructions.map((text, i) => (
            <Text
              textStyle='modal-info'
              color='neutral.primary'
              fontWeight='medium'
              textAlign='center'
              fontSize='14px'
              paddingBottom={i === 1 ? 2 : 0}
              key={text}
            >
              {text}
            </Text>
          ))}
        </ModalBody>
        <ModalFooter pb={0}>
          <VStack spacing={4} w='100%' alignItems='normal'>
            <Button w='100%' onClick={handleClick} isDisabled={isCountdownActive}>
              {genericTranslation.emailVerification.cta}
            </Button>
            {isCountdownActive && (
              <Text>
                {genericTranslation.emailVerification.countdown}{' '}
                <Countdown as='span' seconds={60} onComplete={handleCountdownComplete} />
              </Text>
            )}
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

EmailVerificationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
