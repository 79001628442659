import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const MapIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 16 16' fill='none' color={color}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M8.58398 13.8203C8.2793 14.2012 7.69531 14.2012 7.39062 13.8203C6.0957 12.1699 3.125 8.23438 3.125 6C3.125 3.30859 5.30859 1.125 8 1.125C10.6914 1.125 12.875 3.30859 12.875 6C12.875 8.23438 9.9043 12.1699 8.58398 13.8203ZM8 4.375C7.41602 4.375 6.88281 4.70508 6.57812 5.1875C6.29883 5.69531 6.29883 6.33008 6.57812 6.8125C6.88281 7.32031 7.41602 7.625 8 7.625C8.55859 7.625 9.0918 7.32031 9.39648 6.8125C9.67578 6.33008 9.67578 5.69531 9.39648 5.1875C9.0918 4.70508 8.55859 4.375 8 4.375Z'
          fill={color}
        />
      </svg>
    </Icon>
  );
};

MapIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default MapIcon;
