import { useCallback, useState } from 'react';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { MARKETING_URL } from '~/lib/constants';

export const useMarketingConsent = () => {
  const fetchUrl = useFetchUrl();
  const [revision, setRevision] = useState(null);

  /**
   * @async
   * @description This function returns a prompt for marketing consent when a record of one is
   * not found in our DB.
   * @returns {Promise<Object|undefined>} Resolves with the marketing prompt data if available;
   *                                      otherwise, returns `undefined`.
   * @throws {Error} If the response format is unrecognized.
   */
  const getMarketingPrompt = useCallback(async () => {
    try {
      const response = await fetchUrl(MARKETING_URL);

      const data = await response.json();

      /* If we have a record of the user's marketing consent (user said "yes I consent" or
      "no I don't consent") the endpoint returns an empty object. */
      if (!Object.keys(data).length) {
        return;
      }

      /* If the user has neither given nor refused to give consent then an object with the
      marketing text and a revision number is returned. */
      if (!data?.marketing_revision_id || !data?.text) {
        throw new Error('Unrecognized response format of marketing prompt');
      }

      setRevision(data?.marketing_revision_id ?? null);

      return data;
    } catch (error) {
      // should not block user, but still want record of error
      console.error(error?.message);
    }
  }, [fetchUrl]);

  const applyMarketingConsent = useCallback(
    (pref = false) => {
      if (revision === null) {
        return;
      }

      fetchUrl(MARKETING_URL, {
        body: JSON.stringify({ marketing_agreement_id: revision, accepted: pref }),
        method: 'POST',
      });
    },
    [revision, fetchUrl]
  );

  return {
    applyMarketingConsent,
    getMarketingPrompt,
  };
};
