import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { BadStatusError, handleError } from '/src/lib/errors/index.js';
import { responseStatusIsGood } from '/src/lib/helpers.js';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { LOCATIONS_URL } from '~/lib/constants';

export const useLocationSearchApi = () => {
  const fetchUrl = useFetchUrl();

  const toast = useToast();

  const fetchLocationsByQuery = useCallback(
    async (queryText) => {
      try {
        const response = await fetchUrl(`${LOCATIONS_URL}/autocomplete?queryText=${queryText}`);

        if (!responseStatusIsGood(response)) {
          throw new BadStatusError(response);
        }

        return await response.json();
      } catch (error) {
        handleError(
          toast,
          'Something went wrong while searching. Please try again.',
          'Location Search Failed'
        );

        return { resultItems: [] };
      }
    },
    [fetchUrl, toast]
  );

  return { fetchLocationsByQuery };
};
