import { merge } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import configFallback from '~/lib/config-fallback.json';
import { BASE_URL } from '~/lib/constants';
import { BadStatusError, handleError } from '~/lib/errors';
import { getIsDev, interpolateHex, responseStatusIsGood } from '~/lib/helpers';

const environment = import.meta.env.VITE_ENVIRONMENT ?? 'dev';
const WHITE_LABEL_CONFIG_KEY = 'white-label-config';
const isDev = getIsDev();

let cachedConfig;

export const useConfig = () => {
  const [config, setConfig] = useState(
    cachedConfig ?? {
      ...configFallback,
      isConfigLoaded: false,
      env: environment,
    }
  );
  const isInitializingRef = useRef(false);

  const fetchConfig = useCallback(async () => {
    // unlikely an error will ever occur since we're "fetching" a local static file but just in case
    try {
      const response = await fetch(`${BASE_URL}/config/config.json`, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });

      if (!responseStatusIsGood(response)) {
        throw new BadStatusError(response);
      }

      const data = await response.json();

      const newConfig = {
        /* begin with configFallback as base; overwrite with fetched publisher config
             wherever properties are found; is recursive */
        ...merge(configFallback, data),
        isConfigLoaded: true,
        env: environment,
      };

      if (isDev) {
        // Check for white label config in search params
        const searchParams = new URLSearchParams(window.location.search);
        const whiteLabelConfigString = searchParams.get(WHITE_LABEL_CONFIG_KEY);
        const whiteLabelConfig = whiteLabelConfigString ? JSON.parse(whiteLabelConfigString) : null;

        if (whiteLabelConfig) {
          newConfig.theme.images.logo_img_url = whiteLabelConfig.brandLogo;
          newConfig.theme.colors['brand-tertiary'] = whiteLabelConfig.secondaryColor;
          newConfig.theme.colors['secondary_color'] = whiteLabelConfig.primaryColor;
          newConfig.theme.colors.landingPageGradient = {
            top_right: whiteLabelConfig.secondaryColor,
            middle: interpolateHex(
              whiteLabelConfig.secondaryColor,
              whiteLabelConfig.primaryColor,
              0.5
            ),
            bottom_left: whiteLabelConfig.primaryColor,
          };
          newConfig.theme.colors.brand = {
            primary: whiteLabelConfig.primaryColor,
            secondary: whiteLabelConfig.secondaryColor,
          };
        }
      }

      cachedConfig = newConfig;
      setConfig(newConfig);
    } catch (err) {
      /* the one exception where pasing a toast argument is not possible due to this error
         blocking ChakraProvider from loading */
      handleError(null, err, 'Can not load publisher config');
    }
  }, []);

  useEffect(() => {
    if (!config?.isConfigLoaded) {
      if (isInitializingRef.current) {
        return;
      } else {
        isInitializingRef.current = true;

        fetchConfig();
      }
    }
  }, [config?.isConfigLoaded, fetchConfig]);

  return cachedConfig ?? config;
};
