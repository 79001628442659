import { Box, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '~/components/footer/footer';
import { Header } from '~/components/header/header';
import Search from '~/components/search';
import { useAccountAction } from '~/hooks/account/useAccountAction';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useCardNeedsReauthAlert } from '~/hooks/useCardNeedsReauthAlert';
import { useConfig } from '~/hooks/useConfig';
import usePublisherName from '~/hooks/usePublisherName';
import { useResetPassword } from '~/hooks/useResetPassword';
import { QUERY_PARAMS } from '~/lib/constants';
import { PAGE_CONTAINER_MAX_WIDTH, PAGE_CONTAINER_X_MARGIN } from '~/theme/constants';
import Alerts from './alerts/alerts';
import { SettingsModalContainer } from './modals/settings/settings-modal-container';

const brandsPageRegExp = /^\/brands(?:$|\/search$)/;

export default function PageLayout() {
  const responsive = useBreakpointValue(
    {
      base: {
        showSearchHeader: true,
      },
      md: {
        showSearchHeader: false,
      },
    },
    { fallback: 'md', ssr: false }
  );
  const {
    publisher: { favicon },
  } = useConfig();
  const config = useConfig();
  const sso = config?.account?.sso;

  const { publisherName } = usePublisherName();

  const { pathname } = useLocation();
  useCardNeedsReauthAlert();

  const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false);

  const { isLoggedIn } = useAccountState();
  const { getValidToken, readSsoToken } = useAccountAction();

  // Custom logic to open modals existing in ModalContext
  useResetPassword();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (isLoggedIn && !searchParams.has(QUERY_PARAMS.ONE_TIME_TOKEN)) {
      getValidToken();
    }
  }, [getValidToken, isLoggedIn, sso]);

  useEffect(() => {
    if (typeof window === 'undefined' || !sso) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has(QUERY_PARAMS.ONE_TIME_TOKEN)) {
      readSsoToken();
    }
  }, [readSsoToken, sso]);

  useEffect(() => {
    if (favicon) {
      const linkEl = document.getElementById('favicon');

      if (linkEl) {
        linkEl.setAttribute('type', 'image/x-icon');
        linkEl.setAttribute('href', favicon);
      }
    }
  }, [favicon]);

  const showSearchHeader = responsive.showSearchHeader && brandsPageRegExp.test(pathname);

  return (
    <>
      <Helmet>
        <title>{publisherName}</title>
      </Helmet>

      <VStack minHeight='100vh' width='100%' position='relative' spacing={0}>
        <Header setSettingsModalIsOpen={setSettingsModalIsOpen} />
        {showSearchHeader && (
          <Box width='100%' p={4}>
            <Search />
          </Box>
        )}
        <VStack as='article' flexGrow={1} width='inherit' spacing={0}>
          {isLoggedIn && (
            <Alerts
              width={`calc(100% - ${PAGE_CONTAINER_X_MARGIN} - ${PAGE_CONTAINER_X_MARGIN})`}
              maxWidth={PAGE_CONTAINER_MAX_WIDTH}
              mx='auto'
              my={[2, 2, 2.5, 2.5]}
              position={'relative'}
              zIndex={0}
            />
          )}
          <Outlet />
        </VStack>
        <Footer />

        {/* Modals */}
        <SettingsModalContainer
          isOpen={settingsModalIsOpen}
          onClose={() => {
            setSettingsModalIsOpen(false);
          }}
        />
      </VStack>
    </>
  );
}
