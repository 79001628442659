import {
  Box,
  CloseButton,
  Divider,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocationSearchApi } from '~/api/useLocationSearchApi';
import { ChevronDownIcon, ChevronUpIcon } from '~/components/icons';
import MapMarkerIcon from '~/components/icons/map-marker-icon';
import { useTranslations } from '~/hooks/useTranslations';

export const ZipCodePill = ({ zipCode, onChange, onClear }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [zipCodeInfo, setZipCodeInfo] = useState('');
  const [inputValue, setInputValue] = useState(zipCode || '');
  const [searchResult, setSearchResult] = useState([]);

  const placement = useBreakpointValue({ base: 'bottom', md: 'bottom-end', lg: 'bottom-end' });

  const {
    genericTranslation: { restaurantsPage },
  } = useTranslations();

  const { fetchLocationsByQuery } = useLocationSearchApi();

  const searchByZipCode = useCallback(
    async (zipCode) => {
      if (!zipCode) return [];

      const { resultItems } = await fetchLocationsByQuery(zipCode);
      let places = resultItems
        .filter((item) => item.title.toLowerCase().includes('united states'))
        .map((item) => {
          const zipCodeInfo = { title: item.title };
          const zipCodeMatch = item.title.match(/\b\d{5}\b/);
          zipCodeInfo.zipCode = zipCodeMatch ? zipCodeMatch[0] : null;
          return zipCodeInfo;
        })
        .filter((item) => item.zipCode);

      return places;
    },
    [fetchLocationsByQuery]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(async (query) => {
      const result = await searchByZipCode(query);
      setSearchResult(result);
    }, 300);
  }, [searchByZipCode]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    if (event.target.value.length > 3) {
      debouncedSearch(event.target.value);
    } else {
      setSearchResult([]);
    }
  };

  const handleResultClick = (zipCode) => {
    onClose();
    onChange(zipCode);
  };

  const handlePopoverOpen = () => {
    setInputValue('');
    setSearchResult([]);
    onOpen();
  };

  const clearZipCode = () => {
    setInputValue('');
    setSearchResult([]);
    onClose();
    onClear();
  };

  useEffect(() => {
    const fetchPlaces = async () => {
      setIsLoading(true);
      try {
        const placesData = await searchByZipCode(zipCode);
        if (placesData.length) {
          setZipCodeInfo(placesData[0]);
        } else {
          setZipCodeInfo(null);
        }
      } catch (err) {
        setZipCodeInfo(null);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPlaces();
  }, [searchByZipCode, zipCode]);

  if (isLoading) {
    return (
      <Box>
        <Skeleton height='15px' width='200px' />
      </Box>
    );
  }

  return (
    <Popover isOpen={isOpen} placement={placement}>
      <Stack direction='row' alignItems='center'>
        <PopoverTrigger>
          <Stack
            alignItems='center'
            borderRadius='100px'
            bgColor={isOpen ? 'black' : 'secondary.50'}
            color={isOpen ? 'white' : null}
            direction='row'
            px='10px'
            py='6px'
            spacing={2}
            cursor='pointer'
            onClick={isOpen ? onClose : handlePopoverOpen}
          >
            <MapMarkerIcon />
            <Stack alignItems='center' direction='row' spacing={2.5}>
              <Text>
                {zipCodeInfo ? zipCodeInfo.title : isLoading ? '' : restaurantsPage.searchByZipCode}
              </Text>
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Stack>
          </Stack>
        </PopoverTrigger>
        {zipCode ? (
          <CloseButton
            onClick={clearZipCode}
            size='sm'
            sx={{
              _hover: { background: 'none' },
              _focus: { boxShadow: 'none' },
            }}
          />
        ) : null}
      </Stack>
      <PopoverContent>
        <Stack p={5} spacing={2}>
          <Text fontSize='sm' fontWeight='bold'>
            {restaurantsPage.enterYourAddress}
          </Text>
          <Input
            value={inputValue}
            onChange={handleInputChange}
            placeholder={restaurantsPage.zipCodeAddressOrCity}
          />
          {searchResult.length ? (
            <Stack
              borderRadius='md'
              border='1px solid'
              borderColor='secondary.200'
              cursor='pointer'
              p={3}
              divider={<Divider borderColor='gray.200' />}
            >
              {searchResult.map((result) => (
                <Box key={result.title} py={1} onClick={() => handleResultClick(result.zipCode)}>
                  <Text>{result.title}</Text>
                </Box>
              ))}
            </Stack>
          ) : null}
        </Stack>
      </PopoverContent>
    </Popover>
  );
};

ZipCodePill.propTypes = {
  zipCode: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};
