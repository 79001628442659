import { Box, Text } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { CashbackBagIcon } from '~/components/icons/cashback-bag-icon';
import { useConfig } from '~/hooks/useConfig';
import { useTranslationHelpers } from '~/hooks/useTranslationHelpers';
import { useTranslations } from '~/hooks/useTranslations';
import { OfferTextBlock } from './offer-text-block';

export const CashBackRates = ({ offer }) => {
  const { brandsTranslation } = useTranslations();
  const { offerAmountText } = useTranslationHelpers();
  const {
    publisher: { region },
  } = useConfig();
  const hasCommissionGroups = offer.commission_groups?.length > 0;

  if (!hasCommissionGroups) {
    return (
      <OfferTextBlock
        headingText={brandsTranslation.details.cashbackRates}
        headingIcon={(iconSize) => <CashbackBagIcon boxSize={iconSize} />}
      >
        <Text>
          {brandsTranslation.details.cashbackRatesDesc}{' '}
          <Text as='strong'>
            {offerAmountText({
              offerAmount: offer.customer_offer,
              region,
              rewardType: offer.reward_type,
            })}
          </Text>
          .
        </Text>
      </OfferTextBlock>
    );
  }

  return (
    <OfferTextBlock
      headingText={brandsTranslation.details.cashbackRates}
      headingIcon={(iconSize) => <CashbackBagIcon size={iconSize} />}
    >
      {offer.commission_groups.map((commissionGroup) => {
        return (
          <Box key={commissionGroup?.commission_group_uid}>
            <Text as='strong' fontWeight={600}>
              {offerAmountText({
                offerAmount: commissionGroup.customer_offer,
                region,
                rewardType: commissionGroup.reward_type,
              })}
            </Text>
            <Text>
              {brandsTranslation.onText} {commissionGroup.description ?? '(unknown items)'}
            </Text>
          </Box>
        );
      })}
    </OfferTextBlock>
  );
};

CashBackRates.propTypes = {
  offer: PropTypes.shape({
    commission_groups: PropTypes.array,
    customer_offer: PropTypes.number,
    reward_type: PropTypes.string,
  }),
};
