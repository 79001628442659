import { Button } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useModalAction } from '~/hooks/modal/useModalAction';
import { useConfig } from '~/hooks/useConfig';
import { useTranslations } from '~/hooks/useTranslations';
import { MODALS, OFFER_TYPE, RESTAURANTS } from '~/lib/constants';
import { goUrl, neutralizeEvent } from '~/lib/helpers';
import { useGoogleAnalytics } from '~/lib/state/contexts/GoogleAnalyticsProvider';
import { BrandOfferCtaCardLinked } from './brand-offer-cta-card-linked';
import { BrandOfferCtaNoCards } from './brand-offer-cta-no-cards';

export const BrandOfferCta = ({ brand, offer, showGiftCard, hasLinkedCards, hasCreditCards }) => {
  const { brandsTranslation } = useTranslations();
  const { isLoggedIn } = useAccountState();
  const { openModal } = useModalAction();
  const {
    account: { sign_up_url },
  } = useConfig();

  const { logEvent } = useGoogleAnalytics();

  const handleClickOfferAction = useCallback(() => {
    switch (offer.offer_type) {
      case OFFER_TYPE.AFFILIATE:
        if (offer.tracking_url && typeof window !== 'undefined') {
          logEvent('navigation', {
            action: 'affiliate_link',
            label: offer.name,
            value: offer.brand_uid,
            offer_id: offer.offer_uid,
            tracking_url: offer.tracking_url,
          });
          window.open(offer.tracking_url, '_blank');
        }
        break;

      case OFFER_TYPE.GIFT_CARD:
        showGiftCard();
        break;

      case OFFER_TYPE.CARD_LINKED:
      case OFFER_TYPE.VOUCHER_CODE:
        break;

      default:
        console.warn('click on unhandled offer type:', offer.offer_type, offer);
    }
  }, [logEvent, offer, showGiftCard]);

  const openSignUp = useCallback(
    (e) => {
      neutralizeEvent(e);
      if (sign_up_url) {
        goUrl(sign_up_url);
      } else {
        openModal(MODALS.SIGN_UP);
      }
    },
    [sign_up_url, openModal]
  );

  if (!isLoggedIn) {
    return (
      <>
        <Button variant='baseStyle' px={[6, 6, 12]} onClick={openSignUp}>
          {brandsTranslation.offerType[offer.offer_type]['brandOfferCtaUnauthenticated']}
        </Button>
      </>
    );
  }

  if (brand.category === RESTAURANTS) {
    return hasCreditCards ? (
      <BrandOfferCtaCardLinked brand={brand} offer={offer} />
    ) : (
      <BrandOfferCtaNoCards brand={brand} />
    );
  }

  if (offer.offer_type === OFFER_TYPE.CARD_LINKED) {
    return hasLinkedCards ? (
      <BrandOfferCtaCardLinked brand={brand} offer={offer} />
    ) : (
      <BrandOfferCtaNoCards brand={brand} />
    );
  }

  return (
    <Button my='10px' variant='baseStyle' onClick={handleClickOfferAction}>
      {brandsTranslation.offerType[offer.offer_type]?.brandOfferCtaAuthenticated}
    </Button>
  );
};

BrandOfferCta.propTypes = {
  brand: PropTypes.object,
  offer: PropTypes.object.isRequired,
  showGiftCard: PropTypes.func.isRequired,
  hasLinkedCards: PropTypes.bool.isRequired,
  hasCreditCards: PropTypes.bool.isRequired,
};
