import { Box, Button, CircularProgress, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useFinance } from '~/hooks/useFinance';
import { useTranslations } from '~/hooks/useTranslations';
import { colors } from '~/theme/default';
import { PlusCircleIcon } from '../icons/plus-circle-icon';
import { InstitutionLogo } from '../institution-logo';

const CARD_HEIGHT = '5.25rem';
const ITEMS_PER_ROW_MOBILE = 2;
const ITEMS_PER_ROW = 3;

export const CardsListCompact = ({ onlyShowCreditCards }) => {
  const { isLoading, institutions, linkCards } = useFinance();
  const { financeTranslation } = useTranslations();

  const cardsWithInst = useMemo(() => {
    const cards =
      institutions?.flatMap((institution) =>
        (institution.linked_cards || []).map((card) => ({
          ...card,
          institution,
        }))
      ) ?? [];

    if (onlyShowCreditCards) {
      return cards.filter((card) => card.account_type === 'credit card');
    }

    return cards;
  }, [institutions, onlyShowCreditCards]);

  if (isLoading) {
    return <CircularProgress size={8} isIndeterminate color='brand.primary' />;
  }

  return (
    <Grid
      as='ul'
      columnGap={4}
      display='grid'
      listStyleType='none'
      rowGap={4}
      templateColumns={{
        base: `repeat(${ITEMS_PER_ROW_MOBILE}, 1fr)`,
        md: `repeat(${ITEMS_PER_ROW}, 1fr)`,
      }}
      width='100%'
    >
      {cardsWithInst.map((card) => {
        return (
          <GridItem
            as='li'
            borderRadius='xl'
            boxShadow='0px 2px 6px 0px #00000012'
            key={`${card.institution.institution_name}-${card.name}-${card.mask}`}
            minHeight={CARD_HEIGHT}
            width='100%'
          >
            <Grid
              p={3}
              height='100%'
              columnGap={4}
              templateColumns={{ base: '44px 1fr', md: '52px 1fr' }}
            >
              <Box alignSelf='center' borderRadius='lg' justifySelf='center'>
                <InstitutionLogo
                  color={colors.neutral.primary}
                  institution={card.institution}
                  size='100%'
                />
              </Box>
              <VStack alignItems='start'>
                <Text fontWeight='medium' fontSize='sm' noOfLines={1} title={card.name}>
                  {card.name}
                </Text>
                <Text fontWeight='medium' fontSize='sm'>
                  •••• {card.mask}
                </Text>
              </VStack>
            </Grid>
          </GridItem>
        );
      })}
      <GridItem
        // For smaller viewports, this will make the button span the full width of the grid row if it's the only item in the row
        gridColumn={{
          base: cardsWithInst.length % ITEMS_PER_ROW_MOBILE === 0 ? '1 / -1' : 'auto',
          md: 'auto',
        }}
      >
        <Button
          width={{ base: '100%', md: 'auto' }}
          bg='surface.white'
          display='flex'
          flexDirection='column'
          rowGap={1}
          alignItems='center'
          justifyContent='center'
          height={CARD_HEIGHT}
          boxShadow='0px 2px 6px 0px #00000012'
          variant='unstyled'
          cursor='pointer'
          p={4}
          color='brand.primary'
          fontWeight='normal'
          onClick={linkCards}
          transition='box-shadow 300ms ease-out'
          _hover={{ bg: 'surface.white', boxShadow: '0px 2px 12px 0px #0000002E' }}
        >
          <PlusCircleIcon />
          {financeTranslation.addACard}
        </Button>
      </GridItem>
    </Grid>
  );
};

CardsListCompact.propTypes = {
  onlyShowCreditCards: PropTypes.bool,
};
