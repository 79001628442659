import { Box, useBreakpointValue, useToast } from '@chakra-ui/react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import usePublisherName from '~/hooks/usePublisherName';
import { BRANDS_API_URL, IMPRESSION, SORT_BY } from '~/lib/constants';
import { handleError } from '~/lib/errors';
import { useGoogleAnalytics } from '~/lib/state/contexts/GoogleAnalyticsProvider';
import { LandingPage01Intro } from '~/pages/landing/landing-page-01-intro';
import { LandingPage02GettingStarted } from '~/pages/landing/landing-page-02-getting-started';
import { LandingPage03Brands } from '~/pages/landing/landing-page-03-brands';

const DEFAULT_COUNT = 6;
const countFromEle = (ele) => {
  if (!ele) {
    return DEFAULT_COUNT;
  }
  return Math.min(10, Math.floor(ele.clientWidth / 180));
};

const VERTICAL_AREA = `"text"
    "images"
   "footer"`;
const HORIZ_AREA = `"text images"
   "footer footer"`;
const HORIZ_COLS = '3fr 4fr';
const HORIZ_COLS_END = '1fr 1fr';

const OFFER_BUTTON_LABEL = 'See Our Full Catalog of Brands!';
const OFFER_BUTTON_LABEL_SHORT = 'See Our Brands!';

const RESPONSIVE_VARIATION = {
  base: {
    label: 'base',
    innerFlex: 'column',
    showButtons: 1,
    showSample: true,
    showFooter: false,
    cols: '1fr',
    colsEnd: '1fr',
    rows: 'auto auto auto',
    areas: VERTICAL_AREA,
    brandCount: 0,
    showOfferButton: true,
    offerButtonLabel: OFFER_BUTTON_LABEL_SHORT,
  },
  sm: {
    label: 'sm',
    innerFlex: 'column',
    showButtons: 1,
    showSample: true,
    showFooter: false,
    cols: '1fr',
    colsEnd: '1fr',
    rows: 'auto auto auto',
    areas: VERTICAL_AREA,
    brandCount: 2,
    offerButtonLabel: OFFER_BUTTON_LABEL,
    showOfferButton: true,
  },
  md: {
    label: 'md',
    innerFlex: 'column',
    showButtons: true,
    showSample: true,
    showFooter: true,
    cols: HORIZ_COLS,
    colsEnd: HORIZ_COLS_END,
    rows: 'auto',
    areas: HORIZ_AREA,
    brandCount: 3,
    offerButtonLabel: OFFER_BUTTON_LABEL,
    showOfferButton: true,
  },
  lg: {
    label: 'lg',
    innerFlex: 'row',
    showButtons: 2,
    showSample: true,
    showFooter: true,
    cols: HORIZ_COLS,
    colsEnd: HORIZ_COLS_END,
    rows: 'auto',
    areas: HORIZ_AREA,
    brandCount: 5,
    offerButtonLabel: OFFER_BUTTON_LABEL,
  },
  xl: {
    label: 'xl',
    innerFlex: 'row',
    showButtons: 2,
    showSample: true,
    showFooter: true,
    cols: HORIZ_COLS,
    colsEnd: HORIZ_COLS_END,
    rows: 'auto',
    areas: HORIZ_AREA,
    brandCount: 7,
    offerButtonLabel: OFFER_BUTTON_LABEL,
  },
};

const SIZE = 10;

export default function LandingPage() {
  const fetchUrl = useFetchUrl();
  const toast = useToast();
  const responsive = useBreakpointValue(RESPONSIVE_VARIATION, {
    fallback: 'md',
    ssr: false,
  });

  const { logEvent } = useGoogleAnalytics();

  const [brands, setBrands] = useState([]);
  const [brandCount, setBrandCount] = useState(SIZE);

  const { publisherName } = usePublisherName();

  useEffect(() => {
    const fetchBrands = async () => {
      const searchParams = new URLSearchParams({
        sort: SORT_BY.USER_OFFER_RANK,
        size: SIZE,
        page: 0,
      });
      try {
        const resp = await fetchUrl(BRANDS_API_URL + '?' + searchParams.toString());
        if (resp.status !== 200) {
          throw new Error('Cannot fetch brands');
        }

        const data = await resp.json();
        setBrands(data.content);
      } catch (fetchErr) {
        handleError(toast, fetchErr, 'Cannot fetch brands');
      }
    };

    fetchBrands();
  }, [fetchUrl, toast]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setBrandCount(countFromEle(document.body));
    });
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, [responsive]);

  const imgBrands = useMemo(() => {
    return brands?.slice(0, brandCount) ?? [];
  }, [brandCount, brands]);

  useEffect(() => {
    if (logEvent) {
      logEvent(IMPRESSION, {
        page: 'home_page',
      });
    }
  }, [logEvent]);

  return (
    <Fragment>
      <Helmet>
        <title>{publisherName}</title>
      </Helmet>

      <Box w='100%'>
        <LandingPage01Intro responsive={responsive} imgBrands={imgBrands} />
        <LandingPage02GettingStarted responsive={responsive} />
        <LandingPage03Brands responsive={responsive} imgBrands={imgBrands} />
      </Box>
    </Fragment>
  );
}
