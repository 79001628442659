import { useMemo } from 'react';
import { useConfig } from '~/hooks/useConfig';

export const useBgGradient = () => {
  const { theme } = useConfig();

  return useMemo(() => {
    const colors = theme.colors.landingPageGradient;

    const colorsArr = [colors.top_right, colors.middle, colors.bottom_left].join(', ');

    return [
      `linear(to-t, ${colorsArr})`,
      `linear(to-t, ${colorsArr})`,
      `linear(to-bl, ${colorsArr})`,
    ];
  }, [theme]);
};
