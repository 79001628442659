import dayjs from 'dayjs';
import { startCase } from 'lodash-es';
import { BRANDS_ROUTE, REGIONS } from '~/lib/constants';
import { URL_RE } from '~/lib/regexp';

/**
 * @typedef {import('./state/contexts/AlertContext').Alert} Alert
 */

export function plural(variants, test) {
  const { single, two, many, none, negative } = variants;
  let count;
  if (typeof test === 'number') count = test;
  if (Array.isArray(test)) count = test.length;
  if (count < 0) {
    if ('negative' in variants) return negative;
    return none;
  }
  switch (count) {
    case 0:
      return 'none' in variants ? none : single;
    case 1:
      return single ?? many;
    case 2:
      return two ?? many;
    default:
      return many;
  }
}

/**
 * this is a wrapper for changing the page url.
 * It is for off-site urls -- as in going to the publishers sign on with SSO.
 * same-site changes should use the <Link to="relative-path">...</Link> component
 * or the navigate hook
 * @param url
 */
export function goUrl(url, newTab = false) {
  if (!window) {
    console.error('cannot goUrl - no window object', url);
    return;
  }

  if (newTab) {
    window.open(url, '_blank');
  } else {
    window.location.href = url;
  }
}

export function shortDate(date, ifInvalid = '') {
  const d = dayjs(date);
  if (!d.isValid()) return ifInvalid;
  return d.format('YYYY/MM/DD');
}

export function longDate(date, ifInvalid = '') {
  const d = dayjs(date);
  if (!d.isValid()) return ifInvalid;
  return d.format('MMMM D, YYYY');
}

export function responseStatusIsGood(res) {
  if (!(res && typeof res === 'object')) {
    return false;
  }
  const s = Number(res.status) / 100;
  return Math.floor(s) === 2 || Math.floor(s) === 3;
}

export function neutralizeEvent(e) {
  if (!e?.preventDefault) {
    return;
  }

  e.preventDefault();
  e.stopPropagation();
}

export default function imgUrl(str) {
  if (str && typeof str === 'string') {
    return `url("${str}")`;
  }
  return '';
}

/**
 * providing a relay to current time to allow for test interception
 * @returns {number}
 */
export function currentTimeInMS() {
  return Date.now();
}

export function asPx(n) {
  return `${Number(n)}px`;
}

const SIZES = ['base', 'sm', 'md', 'lg', 'xl'];

/**
 * convert a set of designated values to an array of settings
 * note: 'base' is required.
 * @param config
 * @param {Boolean} toPx
 * @returns {(any)[]} - an array of style values (usually strings or numbers - can be anything)
 */
export function res(config, toPx = false) {
  let base = null;

  for (let size of SIZES) {
    if (size in config) {
      base = config[size];
      break;
    }
  }

  let current = base;

  return SIZES.map((size) => {
    if (size in config) {
      current = config[size];
    }
    return toPx ? asPx(current) : current;
  });
}

export function isEvent(e) {
  return e && typeof e === 'object' && 'target' in e;
}

export const currencySymbols = new Map([
  [REGIONS.US, '$'],
  [REGIONS.UK, '£'],
]);

export function getCurrencySymbol(region) {
  return currencySymbols.get(region) ?? '$';
}

export const currencyUS = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const currencyUK = new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' });

/**
 * @param {number} amount
 * @param {string} keyof REGIONS - REGIONS.US or REGIONS.UK
 * @returns {string}
 */
export const formatCurrency = (amount, region) => {
  const amt = Number(amount);

  if (Number.isNaN(amt)) {
    return '--';
  }

  if (region === REGIONS.UK) {
    return currencyUK.format(amt);
  }

  return currencyUS.format(amt);
};

export function date(d) {
  const toObj = dayjs(d);
  if (!toObj.isValid()) {
    return '--';
  }
  return toObj.format('MM/DD/YY');
}

/**
 * @param {Object} config
 * @returns {string | undefined} Region | undefined
 */
export const getRegion = (config) => config?.publisher?.region;

export function status(s) {
  if (!(s && typeof s === 'string')) {
    return '';
  }
  return startCase(s.toLowerCase());
}

export function isCategory(c) {
  return typeof c === 'object' && c && 'category_id' in c;
}

/**
 * @param {string} string
 * @returns {boolean} boolean
 * @description Validates that a string is a valid URL.
 */

export const isValidUrl = (string) => {
  if (!URL_RE.test(string)) {
    return false;
  }

  return true;
};

/**
 * Creates an id for an alert based on its status, description, and location.
 * @param {Alert} alert
 * @returns {string} id
 */
export const getAlertId = (alert) =>
  `${alert.status}-${alert.description}${alert.location ? `-${alert.location}` : ''}`;

/**
 * A utility for wrapping keyboard event handlers that will only trigger them if the enter or space key is pressed
 * @param {(event: Event) => void} fn The function to be invoked
 * @returns {void}
 */
export const withKeyboard = (fn) => (event) => {
  if (event.key === ' ' || event.key === 'Enter') {
    fn(event);
  }
};

/**
 * Converts a string to a URL-safe string.
 * @param {string} string
 * @returns {string}
 */
export const toUrlSafeString = (string) => {
  if (typeof string !== 'string') {
    console.error('Argument must be a string.');
    return undefined;
  }

  return string
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading/trailing spaces
    .replace(/[^a-z0-9\s-]/g, '') // Remove non-alphanumeric characters except hyphens and spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-'); // Replace multiple hyphens with a single one
};

export const getBrandPath = (brand) =>
  `${BRANDS_ROUTE}/${toUrlSafeString(brand?.name)}/${brand?.brand_uid}`;

export const getCategoryIdFromCategories = (categories, categoryName) => {
  const foundCategory = categories.find((c) => c.category === categoryName);

  return foundCategory?.category_id;
};

export const hexToRgb = (hex) => ({
  r: parseInt(hex.slice(1, 3), 16),
  g: parseInt(hex.slice(3, 5), 16),
  b: parseInt(hex.slice(5, 7), 16),
});

export const rgbToHex = ({ r, g, b }) =>
  `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;

export function interpolateHex(hex1, hex2, factor = 0.5) {
  // Get RGB values
  const rgb1 = hexToRgb(hex1);
  const rgb2 = hexToRgb(hex2);

  // Interpolate each color channel
  const interpolatedRgb = {
    r: Math.round(rgb1.r + (rgb2.r - rgb1.r) * factor),
    g: Math.round(rgb1.g + (rgb2.g - rgb1.g) * factor),
    b: Math.round(rgb1.b + (rgb2.b - rgb1.b) * factor),
  };

  return rgbToHex(interpolatedRgb);
}

export function darkenHex(hex, percent) {
  // Ensure percent is between 0 and 100
  percent = Math.max(0, Math.min(100, percent)) / 100;

  // Get RGB values
  const rgb = hexToRgb(hex);

  // Darken each color channel
  const darkenedRgb = {
    r: Math.round(rgb.r * (1 - percent)),
    g: Math.round(rgb.g * (1 - percent)),
    b: Math.round(rgb.b * (1 - percent)),
  };

  return rgbToHex(darkenedRgb);
}

export function lightenHex(hex, percent) {
  // Ensure percent is between 0 and 100
  percent = Math.max(0, Math.min(100, percent)) / 100;

  // Get RGB values
  const rgb = hexToRgb(hex);

  // Lighten each color channel
  const lightenedRgb = {
    r: Math.round(rgb.r + (255 - rgb.r) * percent),
    g: Math.round(rgb.g + (255 - rgb.g) * percent),
    b: Math.round(rgb.b + (255 - rgb.b) * percent),
  };

  return rgbToHex(lightenedRgb);
}

export const getIsDev = () => {
  return import.meta.env.VITE_ENVIRONMENT === 'dev';
};
