import {
  Box,
  Button,
  CircularProgress,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageContainer } from '~/components/page-container';
import { ZipCodePill } from '~/components/zipcode-pill/zipcode-pill';
import { useSearch } from '~/hooks/useSearch';
import { useTranslations } from '~/hooks/useTranslations';
import { Brand } from '~/pages/brands/brand';

export function Restaurants() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const zipCode = queryParams.get('search_location') ?? null;
  const { genericTranslation: t, brandsTranslation: b } = useTranslations();

  const { brands, showMore, onMore, isBrandsLoading, isCategoriesLoading } = useSearch();

  const responsive = useBreakpointValue(
    {
      base: { columns: 1 },
      sm: { columns: 2 },
      md: { columns: 3 },
      lg: { columns: 4 },
      xl: { columns: 4 },
    },
    {
      fallback: 'md',
      ssr: false,
    }
  );

  const handleNavigate = (zipCode) => {
    const newQueryParams = new URLSearchParams(queryParams);
    newQueryParams.set('search_location', zipCode);
    navigate(`?${newQueryParams.toString()}`);
  };

  const onZipCodeChange = (zipCode) => {
    handleNavigate(zipCode);
  };

  const onZipCodeClear = () => {
    handleNavigate('');
  };

  const brandItems = Array.from(brands.values());
  const isLoading = isBrandsLoading || isCategoriesLoading;
  const noBrandsFound = !isBrandsLoading && !isCategoriesLoading && brands && !brandItems.length;

  return (
    <PageContainer>
      <Stack alignItems='center' direction={{ base: 'column', md: 'row' }} w='100%' mb='7'>
        <Box>
          <Heading color='neutral.primary' fontSize='2xl'>
            {t.categories.restaurants.restaurants_near_you}
          </Heading>
        </Box>
        <ZipCodePill zipCode={zipCode} onChange={onZipCodeChange} onClear={onZipCodeClear} />
      </Stack>
      {isLoading ? (
        <Box>
          <CircularProgress size={8} isIndeterminate color='brand.primary' />
        </Box>
      ) : (
        <SimpleGrid columns={responsive.columns} spacing={5} width='100%'>
          {brandItems.map((brand) => (
            <Brand key={brand.brand_uid} brand={brand} />
          ))}
        </SimpleGrid>
      )}
      {showMore && !isLoading && (
        <Button isDisabled={isLoading} onClick={onMore} alignSelf='center' marginTop={16}>
          {isBrandsLoading ? <Spinner color='white' /> : t.loadMore}
        </Button>
      )}
      {noBrandsFound && (
        <Box>
          <Text>{b.noBrands}</Text>
        </Box>
      )}
    </PageContainer>
  );
}
